<template>
  <div class="row">
    <div class="col-md-4">
      <b-spinner v-if="!ready" variant="info"></b-spinner>
      <b-card :header="event.name" v-if="ready && foundEvent" no-body>
        <b-list-group flush>
          <b-list-group-item>
            Status:
            <span class="text-info">
              {{ event.status.name }}
            </span>
          </b-list-group-item>
          <b-list-group-item>
            Profiel:
            <span class="text-info">
              {{ event.profile.name }}
            </span>
          </b-list-group-item>
          <b-list-group-item>
            Locatie:
            <span class="text-info" v-for="location in event.locations" :key="location.id">
              {{ location.name }}
            </span>
          </b-list-group-item>
          <b-list-group-item v-if="event.group != null">
            Groep:
            <span class="text-info">
              <router-link class="text-info" :to="{name: 'Eventgroep', params: { id: event.group.id }}">{{ event.group.name }}</router-link>
            </span>
          </b-list-group-item>
          <b-list-group-item class="text-center">
            <b-button variant="info" size="sm" @click="makeFavorite">
              <b-icon-star-fill v-if="favorite" />
              <b-icon-star v-else />
              Favoriet
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card no-body class="mt-3" v-if="ready && foundEvent && schedule.entries.length">
        <b-card-header>
          Planning
          <b-badge variant="info" pill>{{ schedule.entries.length }}</b-badge>
        </b-card-header>
        <b-list-group flush>
          <b-list-group-item v-for="item in schedule.entries" :key="item.id">
            <div>{{ item.description }}</div>
            <div class="text-muted small">{{ formatDate(item.starttime) }}</div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card no-body class="mt-3" v-if="ready && foundEvent && attachments.length">
        <b-card-header>
          Bijlagen
          <b-badge variant="info" pill>{{ attachments.length }}</b-badge>
        </b-card-header>
        <b-list-group flush>
          <b-list-group-item v-for="attachment in attachments" :key="attachment.dataurl">
            <div>
              {{ attachment.comment }}
            </div>
            <div class="text-muted small">
              {{ formatDate(attachment.date) }}, {{ attachment.username }}
              <span v-if="attachment.originalname">&ndash; {{ attachment.originalname }}</span>
            </div>
            <div><a :href="attachment.dataurl" target="_blank" class="text-monospace small">{{ attachment.dataurl }}</a></div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
<!--      <b-card no-body class="mt-3" v-if="ready && foundEvent && group.id != null">-->
<!--        <b-card-header>-->
<!--          Groep:-->
<!--          {{ group.name }}-->
<!--        </b-card-header>-->
<!--        <b-list-group flush>-->
<!--          <b-list-group-item v-for="item in group.children" :key="'group'+ item.id">-->
<!--            <div>-->
<!--              <router-link :to="{name: 'Event', params: { id: item.id }}">{{ item.name }}</router-link>-->
<!--            </div>-->
<!--            <div class="text-muted small">-->
<!--              -->
<!--            </div>-->
<!--          </b-list-group-item>-->
<!--        </b-list-group>-->
<!--      </b-card>-->
      <b-alert variant="danger" :show="ready && !foundEvent">Dit evenement is niet gevonden.</b-alert>
    </div>

    <div class="col-md-8 mt-3 mt-md-0" v-if="ready && foundEvent">
      <b-card no-body>
        <b-card-header>
          Personeel
          <b-badge variant="info" pill>{{ personel.length }}</b-badge>
        </b-card-header>
        <b-list-group flush v-if="personel.length">
          <b-list-group-item v-for="person in personel" :key="person.id">
            <div>{{ person.name }} <span class="text-muted">({{ person.role }})</span></div>
            <div class="text-muted small">
              {{ formatDate(person.start)}}
              &ndash;
              {{ formatDate(person.end) }}
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-card-body class="small text-muted font-italic text-center" v-else>
          Er is geen personeel gepland.
        </b-card-body>
      </b-card>
      <div v-for="group in customdata" :key="group.keyword">
        <b-card :header="group.name" class="mt-3" no-body v-if="filterData(group.children).length">
          <b-list-group flush>
            <b-list-group-item v-for="subgroup in filterData(group.children)" :key="subgroup.keyword">
              <div class="font-weight-bold">{{ subgroup.name}}</div>
              <div v-for="item in subgroup.children" :key="item.keyword">
                <div class="row" v-if="item.value != null">
                  <div class="col-md-6">{{ item.name }}:</div>
                  <div class="col-md-6 text-info">
                    <div v-if="Array.isArray(item.value) && item.type === 'Resource'">
                      <ul>
                        <li v-for="bullet in item.value" :key="bullet">{{ bullet.resource.name }}</li>
                      </ul>
                    </div>
                    <div v-else-if="item.type === 'Contact'">
                      <div v-if="item.value.contact.name != null">{{ item.value.contact.name }}</div>
                      <div v-else>{{ item.value.contact.person.name }}</div>
                    </div>
                    <div v-else-if="item.type === 'Human'">
                      {{ item.value.resource.name }}
                    </div>
                    <div v-else-if="item.type === 'Attachment'">
                      <a :href="item.value.dataurl" target="_blank" class="text-monospace small">{{ item.value.originalname }}</a><br />
                      <span class="small" v-if="item.value.comment">{{ item.value.comment }}<br /></span>
                      <small class="text-muted">{{ item.value.date }}, {{ item.value.username }}</small>
                    </div>
                    <div v-else-if="Array.isArray(item.value)">
                      <ul>
                        <li v-for="bullet in item.value" :key="bullet">{{ bullet }}</li>
                      </ul>
                    </div>
                    <div v-html="item.value" v-else></div>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
export default {
  data() {
    return {
      foundEvent: false,
      event: {},
      group: {},
      schedule: {},
      resources: [],
      personel: [],
      customdata: [],
      attachments: [],
      cdata: [],
      elementsloaded: 0,
      favorite: false
    }
  },
  computed: {
    ready() {
      return (this.elementsloaded >= 6)
    }
  },
  methods: {
    ...mapActions(['apiGet']),
    makeFavorite() {
      if(!this.favorite) {
        let favorites = JSON.parse(localStorage.getItem('favorite_events'))
        if(favorites == null) favorites = []
        favorites.push(this.event.id)
        localStorage.setItem('favorite_events', JSON.stringify(favorites))
        this.favorite = true
      } else {
        let favorites = JSON.parse(localStorage.getItem('favorite_events'))
        if(favorites == null) favorites = []
        let id = favorites.indexOf(this.event.id)
        favorites.splice(id, 1)
        localStorage.setItem('favorite_events', JSON.stringify(favorites))
        this.favorite = false
      }
    },

    formatDate(date) {
      const dt = new Date(date)
      const minutes = (dt.getMinutes() < 10) ? "0"+ dt.getMinutes() : dt.getMinutes()
      const hours = (dt.getHours() < 10) ? "0"+ dt.getHours() : dt.getHours()
      return dt.getDate() +"-"+ (dt.getMonth() + 1) +"-"+ dt.getFullYear() +" "+ hours +":"+ minutes
    },

    formatResources(data) {
      let person = {}
      data.forEach((item) => {
        // Roster
        if(item.resource != null
            && item.role != null
            && item.resource.resourcetype === 'human') {
          person = {
            name: item.resource.name,
            role: item.role,
            start: item.start,
            end: item.end
          }
          this.personel.push(person)
        }

        if(item.children != null && item.children.length > 0) {
          this.formatResources(item.children)
        }
      })
    },

    filterData(data) {
      let output = []
      let insert = false
      data.forEach((item) => {
        insert = false
        if(item.children != null) {
          item.children.forEach((subitem) => {
            if (subitem.value !== null) {
              insert = true
              //console.log('Add: %s', item.keyword)
            }
          })
        }

        if(insert)
          output.push(item)
      })
      return output
    }
  },
  mounted() {
    const eventId = this.$route.params.id
    this.apiGet("event/"+ eventId).then((result) => {
      this.event = result.data
      this.foundEvent = true
      this.elementsloaded ++;

      if(this.event.group != null) {
        this.apiGet("group/"+ this.event.group.id).then((result) => {
          this.group = result.data
          this.elementsloaded ++
        })
      } else {
        this.elementsloaded ++
      }

      this.apiGet("event/"+ eventId +"/schedule").then((result) => {
        this.schedule = result.data
        this.elementsloaded ++;
      })
      this.apiGet("event/"+ eventId +"/resourcebookings").then((result) => {
        this.resources = result.data
        this.formatResources(result.data)
        this.elementsloaded ++;
      })
      this.apiGet("event/"+ eventId +"/customdata").then((result) => {
        this.customdata = result.data.groups
        this.elementsloaded ++;
      })
      this.apiGet("event/"+ eventId +"/attachments").then((result) => {
        this.attachments = result.data
        this.elementsloaded ++;
      })

      let favorites = JSON.parse(localStorage.getItem('favorite_events'))
      if(favorites != null && favorites.includes(this.event.id)) {
        this.favorite = true
      }
    }).catch(() => {
      this.elementsloaded = 6
    })
  }
}
</script>